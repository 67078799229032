import React, { useEffect, useState } from "react";
import { cn } from "../lib/helpers";

import { Link } from "gatsby";

import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

import PortableText from "./portableText";
import BrushImage from "./brushImage";

import * as styles from "./searchPage.module.css";
import * as commonStyles from "../styles/common.module.css";

function SearchPage({ search }) {
  const { title, posts = [], services = [] } = search;

  return (
    <div className={styles.container}>
      <h1 className={cn(styles.headline, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
        Vyhledávání
      </h1>
      <h2 className={cn(styles.title, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
        {title}
      </h2>
      {services.length > 0 && (
        <h3 className={cn(styles.title2, commonStyles.colorDarkGray, commonStyles.fontReleway)}>
          Výsledky hledání ve službách
        </h3>
      )}
      <ul className={cn(styles.results)}>
        {services.length > 0 &&
          services.map((service) => {
            const imageData = getGatsbyImageData(service.mainImage, {}, clientConfig.sanity);
            return (
              <li key={`article_${service.slug.current}`} className={cn(styles.resultItem)}>
                <Link to={`/${service.slug.current}`}>
                  <div className={cn(styles.resultContainer)}>
                    {" "}
                    <GatsbyImage
                      className={styles.image}
                      image={imageData}
                      alt={service.mainImage?.alt}
                    />
                    <h3
                      className={cn(
                        styles.resultTitle,
                        commonStyles.colorAccent,
                        commonStyles.fontButlerMedium
                      )}
                    >
                      {service.title}
                    </h3>
                  </div>
                </Link>
              </li>
            );
          })}
      </ul>
      {posts.length > 0 && (
        <h3 className={cn(styles.title2, commonStyles.colorDarkGray, commonStyles.fontReleway)}>
          Výsledky hledání v magazínu
        </h3>
      )}
      <ul className={cn(styles.results)}>
        {posts.length > 0 &&
          posts.map((post) => {
            const imageData = getGatsbyImageData(post.mainImage, {}, clientConfig.sanity);
            return (
              <li key={`article_${post.slug.current}`} className={cn(styles.resultItem)}>
                <Link to={`/magazin/${post.slug.current}`}>
                  <div className={cn(styles.resultContainer)}>
                    <GatsbyImage
                      className={styles.image}
                      image={imageData}
                      alt={post.mainImage?.alt}
                    />
                    <h3
                      className={cn(
                        styles.resultTitle,
                        commonStyles.colorAccent,
                        commonStyles.fontButlerMedium
                      )}
                    >
                      {post.title}
                    </h3>
                  </div>
                </Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default SearchPage;
