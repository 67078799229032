import React from "react";
import { graphql } from "gatsby";

import Layout from "../containers/layout";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import PageContainer from "../components/pageContainer";
import SearchPage from "../components/searchPage";

export const query = graphql`
  query SearchTemplateQuery {
    allServices: allSanityService(sort: { fields: title }) {
      nodes {
        title
        disabled
        slug {
          current
        }
      }
    }
    magazinPreview: allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 6) {
      nodes {
        title
        slug {
          current
        }
      }
    }
    tags: allSanityCategory(sort: { fields: title }) {
      nodes {
        title
        _id
      }
    }
  }
`;

const SearchTemplate = (props) => {
  const { data, errors, pageContext } = props;
  const allServices = data && data?.allServices.nodes;
  const magazinPreview = data && data?.magazinPreview?.nodes;
  const tags = data && data?.tags?.nodes;
  return (
    <Layout services={allServices} magazin={magazinPreview} tags={tags} logoAnimation={false}>
      {errors && <SEO title="GraphQL Error" />}
      {pageContext && (
        <SEO
          title={`Výsledky hledání: ${pageContext.search}`}
          description={`Výsledky hledání: ${pageContext.search}`}
        />
      )}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      <PageContainer>
        <SearchPage search={pageContext} />
      </PageContainer>
    </Layout>
  );
};

export default SearchTemplate;
